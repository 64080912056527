<!--
	@component:	WEBMULTI
	@desc:		This component is used for all kind of content inside of the second column.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2020-03-30
 -->
<template>
  <div class="webmulti">
    <el-form ref="form-webmulti" @submit.native.prevent>
      <ul class="no-padding no-margin">
        <NodeControls
          v-for="item in GuiNodeCh"
          :key="item.Xnodekey"
          :data="item"
          :component="item.XcontrolChild"
          :update-node-key="updateNodeKey"
        ></NodeControls>
      </ul>
    </el-form>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'WEBMULTI',

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: false,
      default() {
        return this.data.Xnodekey;
      }
    }
  },

  computed: {
    GuiNodeCh() {
      return get(this.data, 'GuiNodeCh', []);
    }
  }
};
</script>
